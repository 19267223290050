//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MyPagination from '../../my-pagination.vue'
export default {
    props: [],
    data () {
        return {
            tableData: [],
            pgData: {
                sortname: '',
                sortorder: 'desc'
            },
            loadingController: false
        }
    },
    methods: {
        
    },
    mounted: function() {
        
    }
}
